import {
    CHANGE_PASSWORD_FAILURE,
    CHANGE_PASSWORD_SUCCESS,
    GET_USER_FAILURE,
    GET_USER_SUCCESS,
    SAVE_USER_IN_TABLE_PIVOT_FAILURE,
    SAVE_USER_IN_TABLE_PIVOT_SUCCESS,
    UPDATE_USER_PROFIL_FAILURE,
    UPDATE_USER_PROFIL_SUCCESS
} from "../types";
import {UserInterface} from "../../models/User";

interface UserStateInterface {
    status: boolean,
    data: UserInterface,
    message: string,
    errorCode: number,
}

const initialState: UserStateInterface = {
    "status": null, "errorCode": null, "message": '', "data": null

};

export function profileReducer(state = initialState, action) {
     switch (action.type) {
        case  GET_USER_FAILURE:
        case  SAVE_USER_IN_TABLE_PIVOT_FAILURE:
        case  UPDATE_USER_PROFIL_FAILURE:
        case  CHANGE_PASSWORD_FAILURE:
            return {
                ...initialState,
                "status": action.error.status,
                "errorCode": action.error.errorCode,
                "message": action.error.message,
                "data": action.error.data,
            };
        case  CHANGE_PASSWORD_SUCCESS:
            return {
                ...initialState,
                "status": action.data.status,
                "errorCode": action.data.errorCode,
                "message": action.data.message,
                "data": action.data.data,
            };
        case GET_USER_SUCCESS:
        case  SAVE_USER_IN_TABLE_PIVOT_SUCCESS:
        case  UPDATE_USER_PROFIL_SUCCESS:
             return {
                ...initialState,
                "status": action.data.data.status,
                "errorCode": action.data.data.errorCode,
                "message": action.data.data.message,
                "data": action.data.data.data,
            };
        default:
            return state
    }
}