import {
    SAVE_MEDICALCARD_SUCCESS, SAVE_MEDICALCARD_FAILURE, GET_MEDICALCARDS_SUCCESS, GET_MEDICALCARDS_FAILURE
} from "../types";
import {MedicalCardInterface, MedicalCardListInterface} from "../../models/MedicalCard";


interface UserStateInterface {
    status: boolean,
    data: MedicalCardInterface | MedicalCardListInterface[],
    message: string,
    errorCode: number,
}

const initialState: UserStateInterface = {
    "status": null, "errorCode": null, "message": '', "data": []

};

export function medicalCardReducer(state = initialState, action) {
    switch (action.type) {
        case  SAVE_MEDICALCARD_SUCCESS:
            return {
                "status": action.data.data.status,
                "errorCode": action.data.data.errorCode,
                "message": action.data.data.message,
                "data": action.data.data,
            };
        case  GET_MEDICALCARDS_SUCCESS: {
            return {
                "status": action.data.data.status,
                "errorCode": action.data.data.errorCode,
                "message": action.data.data.message,
                "data": action.data.data.data,
            };
        }
          case  SAVE_MEDICALCARD_FAILURE:
          case  GET_MEDICALCARDS_FAILURE:
            return {
                ...initialState,
                "status": action.error.status,
                "errorCode": action.error.errorCode,
                "message": action.error.message,
            };
        default:
            return state
    }
}