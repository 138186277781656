import {useEffect, useState} from 'react';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import {Form, FormikProvider, useFormik} from 'formik';
import {
    AlertColor,
    Checkbox,
    FormControlLabel,
    IconButton,
    InputAdornment,
    Link,
    Stack,
    TextField
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import Iconify from '../Icon/Icon';
import {login} from '../../services/actions/authAction';
import Alert from '@mui/material/Alert';
import {EMAIL, FORGOT_PASSWORD, LOGIN, PASSWORD, REMEMBER_ME, THREE_ATTEMPTS} from '../../utils/helpers';
import {LoginSchema} from '../../utils/validation';
import {PATHS} from '../../APIs/paths';
import "../../assets/sass/login.scss";
import store from "../../services/store/ConfigureStore";
import {ROLES} from "../../models/User";
import ReCAPTCHA from "react-google-recaptcha";

export default function LoginForm() {
    let navigate = useNavigate();
    let [error, setError] = useState<AlertColor>();
    let [message, setMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [data, setData] = useState( );
    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            recaptcha_token: ""
        },
        validationSchema: LoginSchema,
        onSubmit: (value) => {
            login(value.email, value.password)
                .then((response) => {
                    const newState = store.getState().authState;
                    if (newState.status) {
                        if (newState.data.user.role == ROLES.SUPER_ADMINISTRATOR) {
                            navigate(PATHS.MANAGEMENT_USERS);
                        } else {
                            navigate(PATHS.DASHBOARD_HOME);
                        }
                        window.location.reload();
                        formik.resetForm()
                    } else {
                        setMessage(newState.message);
                        setError("error");
                        formik.setSubmitting(false);
                    }
                })
        }
    });
    const {errors, touched, values, isSubmitting, getFieldProps} = formik;
    const handleShowPassword = () => {
        setShowPassword((show) => !show);
    };
    useEffect(() => {
        let test = message == THREE_ATTEMPTS;
        if (test)
            localStorage.setItem("attempts", String(test))
        else {
            localStorage.removeItem("attempts")
        }
    }, [message])

    return (
        <Stack spacing={4}>
            <FormikProvider value={formik}>
                {message && <Alert severity={error}>{message}</Alert>}

                {  <Form autoComplete="off" noValidate>
                    { localStorage.getItem('attempts') &&  <ReCAPTCHA
                        sitekey="6LfYHMceAAAAAK2zfM2UHhYUkmbXYCzsPVYWRxXA"
                        {...getFieldProps('recaptcha_token')}
                    /> }
                    <Stack      sx={{marginTop: "2px"}} spacing={3}>
                        <TextField
                            fullWidth
                            name="email"
                            autoComplete="email"
                            type="email"
                            label={EMAIL}
                            {...getFieldProps('email')}
                            error={Boolean(touched.email && errors.email)}
                            helperText={touched.email && errors.email}
                        />
                        <TextField
                            fullWidth
                            autoComplete="password"
                            name="password"
                            type={showPassword ? 'text' : 'password'}
                            label={PASSWORD}
                            {...getFieldProps('password')}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleShowPassword} edge="end">
                                            <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}/>
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            error={Boolean(touched.password && errors.password)}
                            helperText={touched.password && errors.password}
                        />
                    </Stack>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{my: 2}}>

                        <Link component={RouterLink} variant="subtitle2" to={PATHS.RESET_PASSWORD} underline="hover">
                            {FORGOT_PASSWORD}
                        </Link>
                    </Stack>
                    <LoadingButton
                        className="btn_login"
                        size="medium"
                        type="submit"
                        color="success"
                        variant="contained"
                        loading={isSubmitting}>
                        {LOGIN}
                    </LoadingButton>
                </Form>}
            </FormikProvider> </Stack>
    );
}
