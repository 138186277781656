import * as Yup from "yup";
import {authMessages} from "../../ressources/lang/fr/auth";

export const SAVE_USER_SCHEMA = Yup.object().shape({
    first_name: Yup.string().required(authMessages.required),
    last_name: Yup.string().required(authMessages.required),
    email: Yup.string().email().required(authMessages.required),
    address: Yup.string().required(authMessages.required),
    role: Yup.string().required(authMessages.required),
    status: Yup.string().required(authMessages.required),
    gender: Yup.string().required(authMessages.required),
    phone_number: Yup.string().matches(/^[0-9]+$/).required(authMessages.required),

});

export const  SAVE_PATIENT_SCHEMA = Yup.object().shape({
    family_situation: Yup.string().required(authMessages.required),
    date_of_birth: Yup.string().required(authMessages.required),
    blood_group: Yup.string(),
});


export const  SEARCH_USER_SCHEMA = Yup.object().shape({
    email: Yup.string().email(authMessages.emailInvalide).required(authMessages.required),
});

export const  UPDATE_PROFILE_USER_SCHEMA = Yup.object().shape({
    first_name: Yup.string().required(authMessages.required),
    last_name: Yup.string().required(authMessages.required),
    email: Yup.string().email().required(authMessages.required),
});
export const  CHANGE_PASSWORD = Yup.object().shape({
    old_password: Yup.string().required(authMessages.required),
    password: Yup.string().required(authMessages.required),
    confirm_password: Yup.string().required(authMessages.required).oneOf([Yup.ref('password'), null], 'Passwords must match')});

