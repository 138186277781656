import axios from "axios";
import {API_TOKEN, ENDPOINTS} from "../../utils/helpers";
import {getHeaders} from "../../utils/helpers/tools";

export function callGetAppointment(data): Promise<any> {
    return axios.get(process.env.REACT_APP_API_BASE_URL + ENDPOINTS.GET_APPOINTMENT + data,
        getHeaders());

}
export function callSaveAppointment(data): Promise<any> {
    return data.id ? axios.put(process.env.REACT_APP_API_BASE_URL + ENDPOINTS.SAVE_APPOINTMENT + data.id,
        {
            ...data
        }, getHeaders()
    ) : axios.post(process.env.REACT_APP_API_BASE_URL + ENDPOINTS.SAVE_APPOINTMENT,
        {
            ...data
        },
        getHeaders()
    );
}

export function callGetAppointments(
    doctor_id?: number,
    patient_id?: number,
    start_date?: string,
    end_date?: string,
    page?: number,
    status?: number,
    orderDirection?: string,
    orderBy?: string,
    rowsPerPage?: number ): Promise<any> {


    let url="" ;
    if (doctor_id !== undefined) {
        url +=  "doctor_id=" + doctor_id
    }
    if (patient_id !== undefined) {
        url += "patient_id=" + patient_id
    }




    if (end_date !== undefined) {
        url += "&end_date=" + end_date
    }
    if (start_date !== undefined) {
        url += "&start_date=" + start_date
    }

    if (status !== undefined) {
        url += "&status=" + status
    }
    if (orderDirection !== undefined) {
        url += "&orderDirection=" + orderDirection
    }
    if (orderBy !== undefined) {
        url += "&orderBy=" + orderBy
    }
    if (page !== undefined) {
        page++;
        url += "&page=" + page
    }
    if (rowsPerPage !== undefined) {
        url += "&limit=" + rowsPerPage
    }
    return axios.get(process.env.REACT_APP_API_BASE_URL + ENDPOINTS.GET_APPOINTMENTS + url,
        getHeaders())
        .then((response) => {
            return Promise.resolve(response)
        })
        .catch((error) => {
            return Promise.reject(error)
        });
}

export function callUpdateStatusAppointment(roleUser: number,ids: Set<number>, status: number, roleId: Set<number>): Promise<any> {
    return axios.post(
        process.env.REACT_APP_API_BASE_URL + ENDPOINTS.UPDATE_APPOINTMENTS,
        {roleUser:roleUser,ids: Array.from(ids), status, role: Array.from(roleId)},
        getHeaders())
        .then((response) => {
            return Promise.resolve(response)
        })
        .catch((error) => {
            return Promise.reject(error)
        });

}
export function  callDeleteAppointment( ids: Set<number>, doctorId:string): Promise<any> {
    let headersStr = `Bearer ${localStorage.getItem(API_TOKEN)}`;
    return axios.delete(
        process.env.REACT_APP_API_BASE_URL + ENDPOINTS.DELETE_APPOINTMENTS,
        {
            headers: {
                Authorization: headersStr
            },
            data: {
                ids: Array.from(ids), doctor_id:doctorId,
            }
       })
}


