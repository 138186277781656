import App from './App';
import ReactDOM from 'react-dom';
import './utils/chart';
import {BrowserRouter} from 'react-router-dom';
import 'nprogress/nprogress.css';
import {SidebarProvider} from './contexts/SidebarContext';
import {Provider} from "react-redux";
import React from 'react';
import store, {persistor} from '../src/services/store/ConfigureStore';
import { PersistGate } from 'redux-persist/integration/react';
//---------------------------------------------
ReactDOM.render(
    <Provider store={store}>
        <PersistGate persistor={persistor} >
            <SidebarProvider>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </SidebarProvider>
        </PersistGate>
    </Provider>
   ,
    document.getElementById('root')
);