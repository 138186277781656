import {createContext, FC, useState} from 'react';

type SidebarContext = { sidebarToggle: any; toggleSidebar: () => void };
export const SidebarContext = createContext<SidebarContext>({} as SidebarContext);
type Props = {
    children: React.ReactNode,
};
export const SidebarProvider: FC<Props> = ({children,}) => {
    const [sidebarToggle, setSidebarToggle] = useState(false);
    const toggleSidebar = () => {
        setSidebarToggle(!sidebarToggle);
    };
    return (<SidebarContext.Provider value={{sidebarToggle, toggleSidebar}}>
        {children}
    </SidebarContext.Provider>);
};