import React, {ChangeEvent, useState} from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    InputAdornment,
    lighten,
    Link,
    List,
    ListItem,
    TextField,
    Theme,
    Tooltip,
    Typography,
} from '@mui/material';
import {styled} from '@mui/material/styles';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import ChevronRightTwoToneIcon from '@mui/icons-material/ChevronRightTwoTone';
import {ALL_SEARCH_RESULTS, MSG_SEARCH} from "../../../../../utils/helpers";

const DialogWrapper = styled(Dialog)(
    () => `
    .MuiDialog-container {
        height: auto;
    }    
    .MuiDialog-paperScrollPaper {
        max-height: calc(100vh - 64px)
    }
`
);

const SearchInputWrapper = styled(TextField)(
    ({theme}) => `
    background: ${theme.colors.alpha.white[100]};
    .MuiInputBase-input {
        font-size: ${theme.typography.pxToRem(17)};
    }
`
);
const DialogTitleWrapper = styled(DialogTitle)(
    ({theme}) => `
    background: ${theme.colors.alpha.black[5]};
    padding: ${theme.spacing(3)}
`
);

function HeaderSearch() {
    const [openSearchResults, setOpenSearchResults] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setSearchValue(event.target.value);
        if (event.target.value) {
            if (!openSearchResults) {
                setOpenSearchResults(true);
            }
        } else {
            setOpenSearchResults(false);
        }
    };
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <Tooltip arrow title="Search">
                <IconButton color="primary" onClick={handleClickOpen}>
                    <SearchTwoToneIcon/>
                </IconButton>
            </Tooltip>
            <DialogWrapper
                open={open}
                keepMounted
                maxWidth="md"
                fullWidth
                scroll="paper"
                onClose={handleClose}>
                <DialogTitleWrapper>
                    <SearchInputWrapper
                        value={searchValue}
                        autoFocus={true}
                        onChange={handleSearchChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchTwoToneIcon/>
                                </InputAdornment>
                            )
                        }}
                        placeholder="Search terms here..."
                        fullWidth
                        label="Search"
                    />
                </DialogTitleWrapper>
                <Divider/>
                {openSearchResults && (
                    <DialogContent>
                        <Box
                            sx={{pt: 0, pb: 1}}
                            display="flex"
                            justifyContent="space-between"
                        >
                            <Typography variant="body2" component="span">
                                Search results for{' '}
                                <Typography
                                    sx={{fontWeight: 'bold'}}
                                    variant="body1"
                                    component="span"
                                >
                                    {searchValue}
                                </Typography>
                            </Typography>
                            <Link href="#" variant="body2" underline="hover">
                                {MSG_SEARCH}
                            </Link>
                        </Box>
                        <Divider sx={{my: 1}}/>
                        <List disablePadding>
                            <ListItem button>
                                <Box flex="1">
                                    <Typography
                                        component="span"
                                        variant="body2"
                                        sx={{
                                            color: (theme: Theme) =>
                                                lighten(theme.palette.secondary.main, 0.5)
                                        }}
                                    >
                                    </Typography>
                                </Box>
                                <ChevronRightTwoToneIcon/>
                            </ListItem>
                            <Divider sx={{my: 1}} component="li"/>
                            <ListItem button>
                                <Box flex="1">
                                    <Typography
                                        component="span"
                                        variant="body2"
                                        sx={{
                                            color: (theme: Theme) =>
                                                lighten(theme.palette.secondary.main, 0.5)
                                        }}
                                    >
                                    </Typography>
                                </Box>
                                <ChevronRightTwoToneIcon/>
                            </ListItem>
                            <Divider sx={{my: 1}} component="li"/>
                            <ListItem button>
                                <Box flex="1">
                                    <Typography
                                        component="span"
                                        variant="body2"
                                        sx={{
                                            color: (theme: Theme) =>
                                                lighten(theme.palette.secondary.main, 0.5)
                                        }}
                                    >
                                    </Typography>
                                </Box>
                                <ChevronRightTwoToneIcon/>
                            </ListItem>
                        </List>
                        <Divider sx={{mt: 1, mb: 2}}/>
                        <Box sx={{textAlign: 'center'}}>
                            <Button color="primary">{ALL_SEARCH_RESULTS}</Button>
                        </Box>
                    </DialogContent>
                )}
            </DialogWrapper>
        </>
    );
}

export default HeaderSearch;