import {
    GET_DATA_PROFIL_FAILURE,
    GET_DATA_PROFIL_SUCCESS,
    LOGIN_FAILURE,
    LOGIN_SUCCESS,
    LOGOUT_FAILURE,
    LOGOUT_SUCCESS, UPDATE_USER_PROFIL_SUCCESS
} from "../types";
import {UserInterface} from "../../models/User";

interface DataAuthInterface {
    user: UserInterface,
    token: string,
    expires_in: string,
}

interface AuthStateInterface {
    status: boolean,
    data: DataAuthInterface,
    message: string,
    errorCode: number,
}

const initialState: AuthStateInterface = {
    "status": null,
    "errorCode": null,
    "message": '',
    "data": null,
};

export function authReducer(state = initialState, action) {
    switch (action.type) {
        case  LOGIN_SUCCESS:
        case  LOGOUT_SUCCESS:
        case  UPDATE_USER_PROFIL_SUCCESS:
        case  GET_DATA_PROFIL_SUCCESS:
            return {
                "status": action.data.status,
                "errorCode": action.data.errorCode,
                "message": action.data.message,
                "data": action.data.data,
            };
        case  LOGIN_FAILURE:
         case  GET_DATA_PROFIL_FAILURE:
            return {
                "status": action.error.status,
                "errorCode": action.error.errorCode,
                "message": action.error.message,
                "data": action.error.data
            };

        case  LOGOUT_FAILURE: {
             return {
                ...initialState,
            };
        }
        default:
            return state
    }
}