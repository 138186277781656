import {
    APPOINTMENTS,
    ARTICLES,
    DOCTORS,
    FOOD_DIARIES,
    HOME,
    MANAGEMENT,
    MEDICAL_CARDS,
    ORDERS,
    PRESCRIPTION,
    PRODUCTS,
    SETTINGS,
    USERS
} from "../utils/helpers";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import {PATHS} from "../APIs/paths";
import AccountCircleTwoToneIcon from "@mui/icons-material/AccountCircleTwoTone";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupIcon from '@mui/icons-material/Group';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import MedicationIcon from '@mui/icons-material/Medication';
import NoteAddIcon from '@mui/icons-material/NoteAdd';

export interface UserInterface {
    id: string,
    status: boolean,
    first_name: string,
    last_name: string,
    email: string,
    password: string,
    phone_number: number,
    address: string,
    role: number,
    user_id: number,
    gender: string,
    family_situation?: number,
    date_of_birth?: string,
    blood_group?: string,
    pivot?: pivotPatient | pivotPartner
}

export interface pivotPatient {
    doctor_id: number,
    patient_id: number,
    status: boolean,
}

export interface pivotPartner {
    doctor_id: number,
    partner_id: number,
    status: boolean,
}

export interface LinkInterface {
    url: string,
    label: string,
    active: boolean,
}

export interface UserListInterface {
    current_page: number,
    data: UserInterface[],
    first_page_url: string,
    from: number,
    last_page: number,
    last_page_url: string,
    links: LinkInterface[],
    next_page_url: string,
    path: string,
    per_page: number,
    perev_page_url: string,
    to: number,
    total: number,
}

export const ROLES = {
    'SUPER_ADMINISTRATOR': 1, 'DOCTOR': 2, 'SECRETARY': 3, 'PATIENT': 5,
};

export const STATUS = {
    'ACTIF': 1, 'INACTIF': 0
};

export const FAMILY_SITUATION = {
    "Situation familiale": [{value: 1, label: "Célibataire"}, {value: 2, label: "Engagé"}, {value: 3, label: "Mariée"},]
};

export const STATUS_USER = {
    "Status": [{value: " ", label: "Tous"}, {value: 1, label: "Actif"}, {value: 0, label: "Inactif"},],
}


export const BLOOD_GROUP = {
    "Groupe sanguin": [
        {value: 'A+', label: 'A+'},
        {value: 'B+', label: 'B+'},
        {value: 'O+', label: 'O+'},
        {value: 'AB+', label: 'AB+'},
        {value: 'A-', label: 'A-'},
        {value: 'B-', label: 'B-'},
        {value: 'O-', label: 'O-'},
        {value: 'AB-', label: 'AB-'},
    ],
};

export const ORDER_DIRECTION = {
    "order_direction": [{value: "desc", label: "DESC"}, {value: "asc", label: "ASC"}]
};

export const ORDER = {
    "order": [
        {value: "id", label: "ID"},
        {value: "first_name", label: "Nom"},
        {value: "last_name", label: "Prénom"},
        {value: "email", label: "Email"},
        {value: "address", label: "Address"},
        {value: "status", label: "Status"},
    ]
};

export const GENDER_USER = {
    "Sexe": [{value: 0, label: "Homme"}, {value: 1, label: "Femme"}],
}

export const CREATE_BY_ROLES = {
    1: [{value: 2, label: "Médecin"}],
    2: [{value: 3, label: "Sécretaire"},
        {value: 4, label: "Partenaire"},
        {value: 5, label: "Patient"},],
    3: [{value: 5, label: "Patient"}]
}

export const SEARCH_BY_ROLES = {
    1: [{value: 2, label: "Médecin"},],
    2: [{value: 3, label: "Sécretaire"},  {value: 5, label: "Patient"},],
    3: [{value: 5, label: "Patient"}]
}

export const SIDEBAR_BY_ROLES = {
    1: {
        heading: MANAGEMENT, items: [{
            name: DOCTORS, icon: GroupIcon, link: PATHS.MANAGEMENT_USERS
        }, {
            name: SETTINGS, icon: SettingsIcon, link: PATHS.MANAGEMENT_PROFILES,
        }]
    }, 2: {
        heading: MANAGEMENT, items: [{
            name: HOME, icon: GroupIcon, link: PATHS.DASHBOARD_HOME
        }, {
            name: USERS, icon: GroupIcon, link: PATHS.MANAGEMENT_USERS
        }, {
            name: APPOINTMENTS, icon: CalendarMonthIcon, link: PATHS.MANAGEMENT_APPOINTMENTS
        }, {
            name: MEDICAL_CARDS, icon: CreateNewFolderIcon, link: PATHS.MANAGEMENT_MEDICAL_CARD
        }, {
            name: PRESCRIPTION, icon: MedicationIcon, link: PATHS.MANAGEMENT_PRESCRIPTION
        }, {
            name: FOOD_DIARIES, icon: MenuBookIcon, link: PATHS.MANAGEMENT_FOOD_DIARIES
        }, {
            name: ARTICLES, icon: BorderColorIcon, link: PATHS.MANAGEMENT_ARTICLES
        }, {
            name: SETTINGS, icon: SettingsIcon, link: PATHS.MANAGEMENT_PROFILES,
        }],
    }, 3: {
        heading: MANAGEMENT, items: [{
            name: HOME, icon: GroupIcon, link: PATHS.DASHBOARD_HOME
        }, {
            name: USERS, icon: GroupIcon, link: PATHS.MANAGEMENT_USERS
        }, {
            name: APPOINTMENTS, icon: CalendarMonthIcon, link: PATHS.MANAGEMENT_APPOINTMENTS
        }, {
            name: SETTINGS, icon: SettingsIcon, link: PATHS.MANAGEMENT_PROFILES,

        }],
    }, 4: {
        heading: MANAGEMENT, items: [

            {
                name: PRODUCTS, icon: AccountCircleTwoToneIcon, link: PATHS.MANAGEMENT_PRODUCTS,

            }, {
                name: ORDERS, icon: NoteAddIcon, link: PATHS.MANAGEMENT_ORDERS,

            }, {
                name: SETTINGS, icon: SettingsIcon, link: PATHS.MANAGEMENT_PROFILES,
            }],
    }, 5: {
        heading: MANAGEMENT, items: [{
            name: HOME, icon: GroupIcon, link: PATHS.DASHBOARD_HOME
        }, {
            name: FOOD_DIARIES, icon: MenuBookIcon, link: PATHS.MANAGEMENT_FOOD_DIARIES
        }, {
            name: APPOINTMENTS, icon: CalendarMonthIcon, link: PATHS.MANAGEMENT_APPOINTMENTS
        }, {
            name: PRESCRIPTION, icon: MedicationIcon, link: PATHS.MANAGEMENT_PRESCRIPTION
        }, {
            name: SETTINGS, icon: SettingsIcon, link: PATHS.MANAGEMENT_PROFILES,
        }],
    }
}


export const WITH_DOCTOR = [3]



