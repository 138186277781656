import {
    ADD_PRESCRIPTION_FAILURE,
    ADD_PRESCRIPTION_SUCCESS,
    GET_PRESCRIPTIONS_FAILURE,
    GET_PRESCRIPTIONS_SUCCESS
} from "../types";
import {PrescriptionInterface, PrescriptionListInterface} from "../../models/Prescription";


interface PrescriptionStateInterface {
    status: boolean,
    data: PrescriptionInterface | PrescriptionListInterface[],
    message: string,
    errorCode: number,
}

const initialState: PrescriptionStateInterface = {
    "status": null, "errorCode": null, "message": '', "data": []

};

export function prescriptionReducer(state = initialState, action) {
    switch (action.type) {
        case  ADD_PRESCRIPTION_SUCCESS:
        case  GET_PRESCRIPTIONS_SUCCESS:
            return {
                "status": action.data.data.status,
                "errorCode": action.data.data.errorCode,
                "message": action.data.data.message,
                "data": action.data.data.data,
            };
        case  ADD_PRESCRIPTION_FAILURE:
        case  GET_PRESCRIPTIONS_FAILURE:
            return {
                ...initialState,
                "status": action.error.status,
                "errorCode": action.error.errorCode,
                "message": action.error.message,
            };
        default:
            return state
    }
}
