import React, {lazy, Suspense} from 'react';
import {Navigate} from 'react-router-dom';
import SidebarLayout from './layouts/SidebarLayout';
import BaseLayout from './layouts/BaseLayout';
import SuspenseLoader from './components/SuspenseLoader';
import Login from "./content/pages/auth/Login";
import ProtectedRoute from "./content/pages/auth/ProtectedRoute";
import {PATHS} from "./APIs/paths";
import {PartialRouteObject} from 'react-router';
const Loader = (Component) => (props) => (
    <Suspense fallback={<SuspenseLoader/>}>
        <Component {...props} />
    </Suspense>);
const Home = Loader(lazy(() => import('./content/pages/applications/Home/Home')));
const Messenger = Loader(lazy(() => import('./content/pages/applications/Messenger')));
const Users = Loader(lazy(() => import('./content/pages/applications/Users')));
const Appointements = Loader(lazy(() => import('./content/pages/applications/Appointments')));
const UserSettings = Loader(lazy(() => import('./content/pages/applications/Settings')));
const MedicalCards = Loader(lazy(() => import('./content/pages/applications/MedicalCards')));
const Prescriptions = Loader(lazy(() => import('./content/pages/applications/Prescriptions')));
const FoodDiaries = Loader(lazy(() => import('./content/pages/applications/FoodDiaries')));
const FoodDiaryPeriods = Loader(lazy(() => import('./content/pages/applications/FoodDiaryPeriods')));
const SendEmailResetPassword = Loader(lazy(() => import('./content/pages/auth/SendEmailResetPassword')));
const ResetPassword = Loader(lazy(() => import('./content/pages/auth/ResetPassword')));
const Status404 = Loader(lazy(() => import('./content/pages/Status/Status404')));
const routes: PartialRouteObject[] = [{
    path: PATHS.ANY, element: <BaseLayout/>, children: [{
        path: PATHS.HOME, element: <ProtectedRoute>
            <Navigate to={PATHS.DASHBOARD_HOME} replace/>
        </ProtectedRoute>
    }, {
        path: PATHS.STATUS, children: [{
            path: PATHS.HOME, element: (<Navigate to={PATHS.STATUS_404} replace/>)
        }]
    }, {
        path: PATHS.ANY, element: <Status404/>
    },]
}, {
    path: PATHS.DASHBOARD, element: (
        <ProtectedRoute>
            <SidebarLayout/>
        </ProtectedRoute>), children: [ {
        path: PATHS.MESSENGER, element: <ProtectedRoute>
            <Messenger/>
        </ProtectedRoute>
    },{
        path: PATHS.HOME, element: <ProtectedRoute>
            <Home/>
        </ProtectedRoute>
     }
        ,
        {
            path: PATHS.HOME_PAGE, element: <ProtectedRoute>
                <Home/>
            </ProtectedRoute>
        } ]
}, {
    path: PATHS.MANAGEMENT, element: (<ProtectedRoute>
        <SidebarLayout/>
    </ProtectedRoute>),
    children: [{
        path: PATHS.HOME, element: (<ProtectedRoute>
            <Navigate to={PATHS.DASHBOARD_HOME} replace/>
        </ProtectedRoute>)
    },{
        path: PATHS.HOME, element: (<ProtectedRoute>
            <Navigate to={PATHS.MANAGEMENT_USERS} replace/>
        </ProtectedRoute>)
    }  ,{
        path: PATHS.USERS, element: <ProtectedRoute>
            <Users/>
        </ProtectedRoute>
    }, {
        path: PATHS.APPOINTMENTS, element: <ProtectedRoute>
            <Appointements/>
        </ProtectedRoute>
    }, {
        path: PATHS.MEDICAL_CARD, element: <ProtectedRoute>
            <MedicalCards/>
        </ProtectedRoute>
    }, {
        path: PATHS.PRESCRIPTIONS, element: <ProtectedRoute>
            <Prescriptions/>
        </ProtectedRoute>
    }, {
        path: PATHS.FOOD_DIARIES, element: <ProtectedRoute>
            <FoodDiaries/>
        </ProtectedRoute>
    },  {
        path: PATHS.FOOD_DIARY_PERIODS, element: <ProtectedRoute>
            <FoodDiaryPeriods/>
        </ProtectedRoute>
    }, {
        path: PATHS.PROFILE, children: [{
            path: PATHS.SETTINGS, element: <ProtectedRoute>
                <UserSettings/>
            </ProtectedRoute>
        },]
    }]
}, {
    path: PATHS.LOGIN, element: (localStorage.getItem('api_token') ? <Navigate to={PATHS.DASHBOARD_HOME} replace/> : <Login/>),
}, {
    path: PATHS.RESET_PASSWORD,
    element: (localStorage.getItem('api_token') ? <Navigate to={ PATHS.DASHBOARD_HOME} replace  /> : <SendEmailResetPassword/>),
}, {
    path: PATHS.FORM_RESET_PASSWORD,
    element: (localStorage.getItem('api_token') ? <Navigate to={ PATHS.DASHBOARD_HOME}  replace/> : <ResetPassword/>),
},];

export default routes;