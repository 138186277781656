import {API_TOKEN} from "../../../utils/helpers";
import React from "react";
import {history} from "../../../utils/helpers/history";
import {PATHS} from "../../../APIs/paths";

const ProtectedRoute = ({children}) => {
    const api_token = localStorage.getItem(API_TOKEN);
    if (api_token && expiredToken(api_token)) {
        localStorage.removeItem("api_token")
        history.push(PATHS.LOGIN)
        window.location.reload()
    } else {
        if (!api_token) {
            localStorage.removeItem("api_token")
            history.push(PATHS.LOGIN)
            window.location.reload()
        } else {
            return children;
        }
    }
};

const expiredToken = (api_token) => {
    const decodedJWT = parseJwt(api_token);
    if (decodedJWT.exp * 1000 < Date.now()) {
        return true
    } else {
        return false
    }
}

const parseJwt = (token) => {
    try {
        return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
        return null;
    }
};


export default ProtectedRoute;