import {
    RESET_PASSWORD_FAILURE,
    RESET_PASSWORD_SUCCESS,
    SEND_EMAIL_RESET_PASSWORD_FAILURE,
    SEND_EMAIL_RESET_PASSWORD_SUCCESS
} from "../types";

type JSONValue =
    | string
    | JSONObject;

interface JSONObject {
    [x: string]: JSONValue;
}

interface ResetPasswordInterface {
    status: boolean,
    data: string | JSONObject,
    message: number,
    errorCode: string,
}

const initialState: ResetPasswordInterface = {
    "status": false,
    "errorCode": "",
    "message": null,
    "data": "",
};

export function resetPasswordReducer(state = initialState, action) {
    switch (action.type) {
        case  SEND_EMAIL_RESET_PASSWORD_SUCCESS:
             return {
                "status": action.data.status,
                "errorCode": action.data.errorCode,
                "message": action.data.message,
                "data": action.data.data,
            };
         case  RESET_PASSWORD_SUCCESS:
            return {
                "status": action.data.status,
                "errorCode": action.data.errorCode,
                "message": action.data.message,
                "data": action.data.data,
            };
         case   RESET_PASSWORD_FAILURE:
            return {
                "status": action.error.status,
                "errorCode": action.error.errorCode,
                "message": action.error.message,
                "data": action.error.data
            };
        case  SEND_EMAIL_RESET_PASSWORD_FAILURE:
          return {
                "status": action.error.status,
                "errorCode": action.error.errorCode,
                "message": action.error.message,
                "data": action.error.data
            };
        default:
            return state
    }
}
