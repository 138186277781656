import {API_TOKEN} from "./constants/global";

export  function getHeaders() {
    let headersStr = `Bearer ${localStorage.getItem(API_TOKEN)}`;
    return {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': "*",
            Authorization: headersStr
        }
    }
}