import React, {useRef, useState} from 'react';
import {NavLink, useNavigate} from 'react-router-dom';
import {logout} from '../../../../services/actions/authAction';
import {LOGOUT, MESSENGER, SETTINGS} from '../../../../utils/helpers';
import {Avatar, Box, Button, Divider, lighten, List, ListItem, ListItemText, Popover, Typography} from '@mui/material';
import InboxTwoToneIcon from '@mui/icons-material/InboxTwoTone';
import {styled} from '@mui/material/styles';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import store from "../../../../services/store/ConfigureStore";
import {PATHS} from "../../../../APIs/paths";

const UserBoxButton = styled(Button)(
    ({theme}) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);
const MenuUserBox = styled(Box)(
    ({theme}) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);
const UserBoxText = styled(Box)(
    ({theme}) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);
const UserBoxLabel = styled(Typography)(
    ({theme}) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);
const UserBoxDescription = styled(Typography)(
    ({theme}) => `
        color: ${lighten(theme.palette.secondary.main, 0.5)}
`
);

function HeaderUserbox() {
    let navigate = useNavigate();
    let newAuthState = store.getState().authState;
    const ref = useRef<any>(null);
    const [isOpen, setOpen] = useState<boolean>(false);
    const handleOpen = (): void => {
        setOpen(true);
    };
    const handleClose = (): void => {
        setOpen(false);
    };
    return (
        <>
            <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
                <Avatar variant="rounded" alt={newAuthState.data.user.first_name} src=""/>
                <UserBoxText>
                    <UserBoxLabel variant="body1">{newAuthState.data.user.last_name}</UserBoxLabel>
                    <UserBoxDescription variant="body2">
                        {}
                    </UserBoxDescription>
                </UserBoxText>
                <ExpandMoreTwoToneIcon sx={{ml: 1}}/>
            </UserBoxButton>
            <Popover
                anchorEl={ref.current}
                onClose={handleClose}
                open={isOpen}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                <MenuUserBox sx={{minWidth: 210}} display="flex">
                    <Avatar variant="rounded" alt={newAuthState.data.user.first_name} src=""/>
                    <UserBoxText>
                        <UserBoxLabel variant="body1">{newAuthState.data.user.first_name}</UserBoxLabel>
                        <UserBoxDescription variant="body2">
                            {}
                        </UserBoxDescription>
                    </UserBoxText>
                </MenuUserBox>
                <Divider sx={{mb: 0}}/>
                <List sx={{p: 1}} component="nav">
                    <ListItem
                        button
                        to={PATHS.DASHBOARD_MESSENGER}
                        component={NavLink}
                    >
                        <InboxTwoToneIcon fontSize="small"/>
                        <ListItemText primary={MESSENGER}/>
                    </ListItem>
                    <ListItem
                        button
                        to={PATHS.MANAGEMENT_PROFILES}
                        component={NavLink}
                    >
                        <AccountTreeTwoToneIcon fontSize="small"/>
                        <ListItemText primary={SETTINGS}/>
                    </ListItem>
                </List>
                <Divider/>
                <Box sx={{m: 1}}>
                    <Button color="primary" fullWidth onClick={() => {
                        logout()
                            .then((data) => {
                                    navigate(PATHS.LOGIN);
                                }
                            )
                    }}>
                        <LockOpenTwoToneIcon sx={{mr: 1}}/>
                        {LOGOUT}</Button>
                </Box>
            </Popover>
        </>
    );
}

export default HeaderUserbox;