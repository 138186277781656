import {Box, Card, Container, Typography} from '@mui/material';
import {Helmet} from "react-helmet";
import LoginForm from "../../../components/Forms/LoginForm";
import React from "react";
import {LOGIN} from '../../../utils/helpers';
import Accueil from "../../accueil";
// ----------------------------------------------------------------------
export default function Login() {
    return (
        <>
            <Helmet>
                <title>{LOGIN}</title>
            </Helmet>
            <Accueil component={
                <Container maxWidth="sm">
                    <Card sx={{textAlign: 'center', mt: 3, p: 4}}>
                        <Box textAlign="center">
                            <Typography align="center" variant="h1" gutterBottom>
                                {LOGIN}
                            </Typography> <br/><br/>
                            <LoginForm/>
                        </Box>
                    </Card>
                </Container>
            }/>

        </>);
}