export const DESCRIPTION_APP = 'Vous affore un coaching nutritionnel personnalisé par un médecin spécialiste en nutrithérapie.'
export const MYDOCTIPLUS = "MyDoctiPlus"
export const MESSENGER = "Messenger"
export const SETTINGS = "Profile"
export const TEMPLATES = "Templates"
export const DOCTORS = "Medecins"
export const DOCTOR = "Medecin"
export const SECRETARY = "Secrétaire"
export const MANAGEMENT = "Management"
export const APPOINTMENTS = "Rendez-vous"
export const MEDICAL_CARDS = "Fichiers médicaux"
export const PRESCRIPTION = "Ordonnances"
export const FOOD_DIARIES = "Journaux alimentaires"
export const FOOD_DIARY_PERIODS = "Jounaux alimentaires"
export const CATEGORIES = "Catégories"
export const ARTICLES = "Articles"
export const QCM = "QCM"
export const PRODUCTS = "Produits"
export const ORDERS = "Commandes"
export const API_TOKEN = "api_token"
export const COPYRIGHT = "2022-My Docti Plus"