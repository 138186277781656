export const UPDATE_USER_PROFIL_SUCCESS= 'UPDATE_USER_PROFIL_SUCCESS';
export const UPDATE_USER_PROFIL_FAILURE= 'UPDATE_USER_PROFIL_FAILURE';
export const ADD_USER_SUCCESS= 'ADD_USER_SUCCESS';
export const ADD_USER_FAILURE= 'ADD_USER_FAILURE';
export const GET_USERS_SUCCESS= 'GET_USERS_SUCCESS';
export const GET_USERS_FAILURE= 'GET_USERS_FAILURE';
export const GET_USER_SUCCESS= 'GET_USER_SUCCESS';
export const GET_USER_FAILURE= 'GET_USER_FAILURE';
export const UPDATE_STATUS_USER_SUCCESS= 'UPDATA_STATUS_USER_SUCCESS';
export const UPDATE_STATUS_USER_FAILURE= 'UPDATA_STATUS_USER_FAILURE';
export const ADD_APPOINTMENT_SUCCESS= 'ADD_APPOINTMENT_SUCCESS';
export const ADD_APPOINTMENT_FAILURE= 'ADD_APPOINTMENT_FAILURE';
export const GET_APPOINTMENTS_SUCCESS= 'GET_APPOINTMENTS_SUCCESS';
export const GET_APPOINTMENTS_FAILURE= 'GET_APPOINTMENTS_FAILURE';
export const GET_APPOINTMENT_SUCCESS= 'GET_APPOINTMENT_SUCCESS';
export const GET_APPOINTMENT_FAILURE= 'GET_APPOINTMENT_FAILURE';
export const UPDATE_STATUS_APPOINTMENT_SUCCESS= 'UPDATE_STATUS_APPOINTMENT_SUCCESS';
export const UPDATE_STATUS_APPOINTMENT_FAILURE= 'UPDATE_STATUS_APPOINTMENT_FAILURE';
export const DELETE_APPOINTMENT_SUCCESS= 'DELETE_APPOINTMENT_SUCCESS';
export const DELETE_APPOINTMENT_FAILURE= 'DELETE_APPOINTMENT_FAILURE';
export const ADD_PRESCRIPTION_SUCCESS= 'ADD_PRESCRIPTION_SUCCESS';
export const ADD_PRESCRIPTION_FAILURE= 'ADD_PRESCRIPTION_FAILURE';
export const GET_PRESCRIPTIONS_SUCCESS= 'GET_PRESCRIPTIONS_SUCCESS';
export const GET_PRESCRIPTIONS_FAILURE= 'GET_PRESCRIPTIONS_FAILURE';
export const ADD_FOOD_DIARY_SUCCESS= 'ADD_FOOD_DIARY_SUCCESS';
export const ADD_FOOD_DIARY_FAILURE= 'ADD_FOOD_DIARY_FAILURE';
export const GET_FOOD_DIARIES_SUCCESS= 'GET_FOOD_DIARIES_SUCCESS';
export const GET_FOOD_DIARIES_FAILURE= 'GET_FOOD_DIARIES_FAILURE';
export const UPDATE_STATUS_FOOD_DIARIES_SUCCESS= 'UPDATE_STATUS_FOOD_DIARIES_SUCCESS';
export const UPDATE_STATUS_FOOD_DIARIES_FAILURE= 'UPDATE_STATUS_FOOD_DIARIES_FAILURE';
export const UPDATE_STATUS_FOOD_DIARY_PERIODS_SUCCESS= 'UPDATE_STATUS_FOOD_DIARY_PERIODS_SUCCESS';
export const UPDATE_STATUS_FOOD_DIARY_PERIODS_FAILURE= 'UPDATE_STATUS_FOOD_DIARY_PERIODS_FAILURE';
export const ADD_FOOD_DIARY_PERIOD_SUCCESS= 'ADD_FOOD_DIARY_PERIOD_SUCCESS';
export const ADD_FOOD_DIARY_PERIOD_FAILURE= 'ADD_FOOD_DIARY_PERIOD_FAILURE';
export const GET_FOOD_DIARY_PERIOD_SUCCESS= 'GET_FOOD_DIARY_PERIOD_SUCCESS';
export const GET_FOOD_DIARY_PERIOD_FAILURE= 'GET_FOOD_DIARY_PERIOD_FAILURE';
export const UPDATE_STATUS_FOOD_DIARY_PERIOD_SUCCESS= 'UPDATE_STATUS_FOOD_DIARY_PERIOD_SUCCESS';
export const UPDATE_STATUS_FOOD_DIARY_PERIOD_FAILURE= 'UPDATE_STATUS_FOOD_DIARY_PERIOD_FAILURE';
export const DOWNLOAD_PDF_FOOD_DIARY_SUCCESS= 'DOWNLOAD_PDF_FOOD_DIARY_SUCCESS';
export const DOWNLOAD_PDF_FOOD_DIARY_FAILURE= 'DOWNLOAD_PDF_FOOD_DIARY_FAILURE';
export const SAVE_USER_IN_TABLE_PIVOT_SUCCESS= 'SAVE_USER_IN_TABLE_PIVOT_SUCCESS';
export const SAVE_USER_IN_TABLE_PIVOT_FAILURE= 'SAVE_USER_IN_TABLE_PIVOT_FAILURE';
export const CHANGE_PASSWORD_SUCCESS= 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE= 'CHANGE_PASSWORD_FAILURE';
