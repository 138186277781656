import axios, {AxiosError} from "axios";
import {ENDPOINTS} from "../../utils/helpers";
import {getHeaders} from "../../utils/helpers/tools";

export function callGetPatientsOrPartner(email:string): Promise<any> {
    return axios.get(process.env.REACT_APP_API_BASE_URL + ENDPOINTS.GET_PATIENT_OR_PARTNER + email,
        getHeaders());
}

export function callSaveUser(data): Promise<any> {
     return data.id ? axios.put(process.env.REACT_APP_API_BASE_URL + ENDPOINTS.EDIT_USER + data.id, {
        ...data
    }, getHeaders()) : axios.post(process.env.REACT_APP_API_BASE_URL + ENDPOINTS.ADD_USER, {
        ...data
    }, getHeaders());
}
export function callSetPatientsOrPartner(id): Promise<any> {
    return axios.post(process.env.REACT_APP_API_BASE_URL + ENDPOINTS.SET_USER, {
        id
    }, getHeaders());
}

export function callGetUsers(role: number, page?: number, status?: number, orderDirection?: string, orderBy?: string, rowsPerPage?: number, search?: string): Promise<any> {

    let url = "role=" + role;

    if (status !== undefined) {
        url += "&status=" + status
    }
    if (orderDirection !== undefined) {
        url += "&orderDirection=" + orderDirection
    }
    if (orderBy !== undefined) {
        url += "&orderBy=" + orderBy
    }
    if (search !== undefined) {
        url += "&search=" + search
    }
    if (page !== undefined) {
        page++;
        url += "&page=" + page
    }
    if (rowsPerPage !== undefined) {
        url += "&limit=" + rowsPerPage
    }
    return axios.get(process.env.REACT_APP_API_BASE_URL + ENDPOINTS.ADMIN_GET_USERS + url, getHeaders())
        .then((response) => {
            return Promise.resolve(response)
        })
        .catch((error) => {
            return Promise.reject(error)
        });
}

export function callDeleteUsers(roleUser: number, ids: Set<number>, status: boolean, roleId: Set<number>): Promise<any> {
    return axios.post(process.env.REACT_APP_API_BASE_URL + ENDPOINTS.ADMIN_DELETE_USERS, {
        roleUser: roleUser,
        ids: Array.from(ids),
        status:status,
        role: Array.from(roleId)
    }, getHeaders())
        .then((response) => {
            return Promise.resolve(response)
        })
        .catch((error) => {
            return Promise.reject(error)
        });

}