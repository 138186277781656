export const EMAIL = 'Email';
export const PASSWORD = 'Mot de passe';
export const FORGOT_PASSWORD = 'Mot de passe oublie?';
export const PHONE_NUMBER = 'N° Tél';
export const LOGIN = 'Connexion';
export const REMEMBER_ME = "Se souvenir de mot de passe?";
export const SEND_EMAIL = "Veuillez entrer votre adresse e-mail pour réinitialiser votre mot de passe";
export const ASK_RESET_PASSOWRD = "Saisissez un nouveau mot de passe. Nous vous recommandons vivement de créer un mot de passe unique, que vous n'utilisez pas pour d'autres sites Web. Remarque: Une fois modifié, vous ne pouvez pas réutiliser votre ancien mot de passe!";
export const SEND = "Envoyer";
export const RESET_PASSWORD = "Réinitialiser le mot de passe";
export const ADD_APPOINTMENT = "Créer un rendez-vous";
export const ADD_USER = "Créer un utilisateur";
export const EDIT_USER = "Modifier un utilisateur";
export const DELETE_USERS = "Supprimer des utilisateurs";
export const EDIT_APPOINTMENT = "Modifier un rendez-vous";
export const DELETE_APPOINTMENTS = "Supprimer des rendez-vous";
export const EDIT_MEDICAL_CARDS = "Modifier une fiche médicale";
export const MEDICAL_CARD = "Fiche médicale";
export const CONFIRM_DELETE = "Êtes-vous sûr de vouloir la modification le statut ?";
export const VALIDATE = "Valider";
export const SEARCH = "Rechercher";
export const DOWNLOAD = "Télecharger";
export const CANCEL = "Annuler";
export const SCANNE_QR_CODE = "Scanner votre Qr Code";
export const BLOCK = "Bloquer";
export const REPORT = "Signaler";
export const TEXT_SEARCH = "Recherche...";
export const ATTACH_FILE = "Joindre un fichier";
export const AA_TXT = "Aa...";
export const ACTIONS = "Actions";
export const STATUS = "Status";
export const UPDATE_STATUS = "Modification le status";
export const FAMILY_SITUATION_TEXT = "Situation familiale";
export const BLOOD_GROUP_TEXT = "Groupe sanguin";
export const PATIENTS = "Patients";
export const DATA_OF_BIRTH = "Date de naissance";
export const ID = "Id";
export const SELECT_ALL = "Tous coucher";
export const FULL_NAME = "Nom et prénom";
export const COLON  = ":";
export const PERIODS = "Les périodes";
export const CREATED_BY = "Créateur";
export const FIRST_NAME = "Nom";
export const LAST_NAME = "Prénom";
export const ADDRESS = "Address";
export const DATE = "Date";
export const EDIT = "Modifier";
export const CALL_VOCAL = "Passer un appel vocal";
export const CALL_VIDEO = "Passer un appel vidéo";
export const INFO_CONVERSATION = "Informations sur les conversations";
export const CUSTOMIZE_CHAT = "Personnaliser le discussion";
export const CHEARCH_IN_CONVERSATION = "Rechercher dans la conversation";
export const PRIVACY_SUPPORT = "Confidentialité et assistance";
export const DEACTIVATE_NOTIFICATION = "Désactiver les notifications";
export const IGNORE_MESSAGES = "Ignorer les messages";
export const SIGNALE_AVIS_CONVERSATION = "Donnez votre avis et signalez la conversation";
export const ALL_SEARCH_RESULTS = "Afficher tous les résultats de la recherche";
export const LOGOUT = "Déconnexion";
export const MSG_SEARCH = "Recherche Avancée";
export const ROLE = "Role";
export const ORDER_DIRECTION_LABEL = "Trier";
export const ORDER_LABEL = "Trier par";
export const GENDER = "Sexe";
export const ESPACE = " ";
export const ROWS_PER_PAGE = "Lignes par page:";
export const THREE_ATTEMPTS = "Tentatives de connexion trop nombreuses. Veuillez essayer de nouveau dans 60 secondes.";
export const UNAUTHORISED_STATUS = 401;
export const MESSAGE_PAGE_404 = "La page que vous recherchez n'existe pas.";
export const START_DATE = "Date de début";
export const END_DATE = "Date de fin";
export const CONTENT = "Contenu ";
export const CANCELED = "Annulé";
export const FINISHED = "Terminé";
export const INPROGRESS = "En cours";
export const PENDING = "En attente";
export const HEIGHT = "Taille";
export const WEIGHT = "Poids";
export const WAISTLINE = "Size";
export const DESCRIPTION = "Description";
export const ADD_PPRESCRIPTION = "Créer une ordonnance";
export const ADD_FOOD_DIARY = "Créer un journal alimentaire";
export const ADD_FOOD_DIARY_PERIOD = "Créer une période du journal alimentaire";
export const UPDATE_FOOD_DIARY_PERIOD = "Modifer la période du journal alimentaire";
export const FOOD_DIARIES_PERIODS = "Les périodes d'un journal alimentaire";
export const FOOD_DIARY = "Journal alimentaire";
export const SEARCH_BY_FIRST_NAME_LAST_NAME = "Rechercher par nom et prénom";
export const ADD = "Ajouter";
export const REQUIRED_INPUT = " (*)";
export const UPDATE_PROFILE = "Modifer le profile";
export const OLD_PASSWORD = 'Ancien mot de passe';
export const NEW_PASSWORD = 'Nouveau mot de passe';
export const CONFIRMATION_PASSWORD = 'Confirmer le mot de passe';
export const UPDATE_USER = 'Modifier l\'utilisateur';
export const DELETE_USER = 'Supprimer l\'utilisateur';
export const SHOW_MEDICAL_CARD = 'Consulter la fiche médicale';
export const UPDATE_MEDICAL_CARD = 'Modifier la fiche médicale';
export const ADD_MEDICAL_CARD = 'Ajouter une fiche médical';
export const DELETE_APPOINTMENT = 'Supprimer un rendez-vous';
export const UPDATE_APPOINTMENT = 'Modifier un rendez-vous';
export const SHOW_FOOD_DIARY_PERIODS = 'Consulter les periods de journal alimentaire';
export const DOWNLOAD_PDF = 'Télécharger le pdf';
export const SHOW_QR_CODE = 'Afficher QR code';
