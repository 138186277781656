import {useState,createContext} from 'react';
import {ThemeProvider} from '@mui/material';
import {themeCreator} from './base';
import {StylesProvider} from '@mui/styles';

export const ThemeContext = createContext(
    (themeName: string): void => {
    }
);
type Props = {
    children: React.ReactNode,
};

const ThemeProviderWrapper: React.FC<Props> = ({
                                                   children,
                                               }) => {
    const curThemeName = localStorage.getItem('appTheme') || 'PureLightTheme';
    const [themeName, _setThemeName] = useState(curThemeName);
    const theme = themeCreator(themeName);
    const setThemeName = (themeName: string): void => {
        localStorage.setItem('appTheme', themeName);
        _setThemeName(themeName);
    };

    return (
        <StylesProvider injectFirst>
            <ThemeContext.Provider value={setThemeName}>
                <ThemeProvider theme={theme}>{children}</ThemeProvider>
            </ThemeContext.Provider>
        </StylesProvider>
    );
};

export default ThemeProviderWrapper;
