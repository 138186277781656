import {
    ADD_FOOD_DIARY_PERIOD_FAILURE,
    ADD_FOOD_DIARY_PERIOD_SUCCESS,
    GET_FOOD_DIARY_PERIOD_FAILURE,
    GET_FOOD_DIARY_PERIOD_SUCCESS,
    UPDATE_STATUS_FOOD_DIARY_PERIOD_FAILURE,
    UPDATE_STATUS_FOOD_DIARY_PERIOD_SUCCESS,
    UPDATE_STATUS_FOOD_DIARY_PERIODS_FAILURE,
    UPDATE_STATUS_FOOD_DIARY_PERIODS_SUCCESS
} from "../types";
import {FoodDiaryPeriodsInterface, FoodDiaryPeriodsListInterface} from "../../models/FoodDiaryPeriods";

interface FoodDiaryPeriodsStateInterface {
    status: boolean,
    data: FoodDiaryPeriodsInterface | FoodDiaryPeriodsListInterface[],
    message: string,
    errorCode: number,
}

const initialState: FoodDiaryPeriodsStateInterface = {
    "status": null, "errorCode": null, "message": '', "data": []

};

export function foodDiaryPeriodsReducer(state = initialState, action) {
    switch (action.type) {
        case  ADD_FOOD_DIARY_PERIOD_SUCCESS:
        case  GET_FOOD_DIARY_PERIOD_SUCCESS:
            return {
                "status": action.data.data.status,
                "errorCode": action.data.data.errorCode,
                "message": action.data.data.message,
                "data": action.data.data.data,
            };
        case  UPDATE_STATUS_FOOD_DIARY_PERIODS_SUCCESS:
            return {
                ...initialState,
                "status": action.data.status,
                "errorCode": action.data.errorCode,
                "message": action.data.message,
            };
        case  ADD_FOOD_DIARY_PERIOD_FAILURE:
        case  UPDATE_STATUS_FOOD_DIARY_PERIODS_FAILURE:
        case  GET_FOOD_DIARY_PERIOD_FAILURE:
            return {
                ...initialState,
                "status": action.error.status,
                "errorCode": action.error.errorCode,
                "message": action.error.message,
            };
        default:
            return state
    }
}