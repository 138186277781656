import {
    ADD_APPOINTMENT_FAILURE,
    ADD_APPOINTMENT_SUCCESS, DELETE_APPOINTMENT_FAILURE, DELETE_APPOINTMENT_SUCCESS,
    GET_APPOINTMENT_FAILURE,
    GET_APPOINTMENT_SUCCESS,
    GET_APPOINTMENTS_FAILURE,
    GET_APPOINTMENTS_SUCCESS, UPDATE_STATUS_APPOINTMENT_FAILURE, UPDATE_STATUS_APPOINTMENT_SUCCESS
} from "../types";
import {AppointmentListInterface, AppointmentsInterface} from "../../models/Appointment";

interface AppointmentStateInterface {
    status: boolean,
    data: AppointmentsInterface | AppointmentListInterface[],
    message: string,
    errorCode: number,
}

const initialState: AppointmentStateInterface = {
    "status": null, "errorCode": null, "message": '', "data": []
};
export function appointmentReducer(state = initialState, action) {

    switch (action.type) {
        case  GET_APPOINTMENTS_SUCCESS:
            return {
                "status": action.data.data.status,
                "errorCode": action.data.data.errorCode,
                "message": action.data.data.message,
                "data": action.data.data.data,
            };
        case  ADD_APPOINTMENT_SUCCESS:
             return {
                "status": action.data.data.status,
                "errorCode": action.data.data.errorCode,
                "message": action.data.data.message,
                "data": action.data.data.data,
            };
        case  DELETE_APPOINTMENT_SUCCESS: {
            return {
                "status": action.data.status,
                "errorCode": action.data.errorCode,
                "message": action.data.message,
                "data": action.data.data,
            };
        }
        case  UPDATE_STATUS_APPOINTMENT_SUCCESS:
            return {
                "status": action.data.status,
                "errorCode": action.data.errorCode,
                "message": action.data.message,
                "data": action.data,
            };
        case  ADD_APPOINTMENT_FAILURE:
        case  GET_APPOINTMENTS_FAILURE:
        case  GET_APPOINTMENT_FAILURE:
        case  UPDATE_STATUS_APPOINTMENT_FAILURE:
        case  DELETE_APPOINTMENT_FAILURE:
            return {
                ...initialState,
                "status": action.error.status,
                "errorCode": action.error.errorCode,
                "message": action.error.message,
            };
        default:
            return state
    }
}