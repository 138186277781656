import {applyMiddleware, createStore} from 'redux';
import thunkMiddleware from 'redux-thunk';
import reducer from '../reducers';
import {composeWithDevTools} from "@redux-devtools/extension";
import {persistReducer, persistStore} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['authState']
};
const persistedReducer = persistReducer(persistConfig, reducer);
const store = createStore(
    persistedReducer,
    composeWithDevTools(applyMiddleware(
        thunkMiddleware,
    ))
);
export const persistor = persistStore(store);
export default store;