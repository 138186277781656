import MmsTwoToneIcon from '@mui/icons-material/MmsTwoTone';
import {PATHS} from "../../../../APIs/paths";
import {HOME, MESSENGER} from "../../../../utils/helpers";
import HomeIcon from '@mui/icons-material/Home';

export interface MenuItem {
    link?: string;
    icon?: any;
    badge?: string;
    items?: MenuItem[];
    name: string;
}

export interface MenuItems {
    items: MenuItem[];
    heading: string;
}


const menuItems: MenuItems[] = [ ];

export default menuItems;