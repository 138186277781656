import * as Yup from "yup";
import {authMessages} from "../../ressources/lang/fr/auth";
export const ResetPassword = Yup.object().shape({
    email: Yup.string().email(authMessages.emailInvalide).required(authMessages.required),
    password: Yup.string().required(authMessages.required),
    password_confirmation:Yup.string().required(authMessages.required).when("password", {
        is: val => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
            [Yup.ref("password")],
            authMessages.password_confirmation
        )
    })
});