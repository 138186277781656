import {callLogin, callLogout} from "../APIs";
import {API_TOKEN} from "../../utils/helpers";
 import {LOGIN_FAILURE, LOGIN_SUCCESS, LOGOUT_FAILURE, LOGOUT_SUCCESS} from "../types";
import store from "../store/ConfigureStore";

export function login(email: string, password: string): Promise<any> {
     return callLogin(email, password)
        .then((response) => {
            localStorage.setItem(API_TOKEN, response.data.data.user.api_token);
            store.dispatch(successLogin(response.data));
        })
        .catch((error) => {
            store.dispatch(failureLogin(error.response.data));
        });
}
export   function logout(): Promise<any> {
    return   callLogout()
        .then((response) => {
            store.dispatch(successLogout());
        })
        .catch((error) => {
            store.dispatch(failureLogout(error.response));
        });
}

function successLogin(data:number) {
    return {type: LOGIN_SUCCESS, data};
}

function failureLogin(error) {
    return {type: LOGIN_FAILURE, error};
}

function successLogout() {
    return {type: LOGOUT_SUCCESS};
}

function failureLogout(error) {
    return {type: LOGOUT_FAILURE, error};
}


