import {
    ADD_USER_FAILURE,
    ADD_USER_SUCCESS,
    GET_USERS_FAILURE,
    GET_USERS_SUCCESS,
    UPDATE_STATUS_USER_FAILURE,
    UPDATE_STATUS_USER_SUCCESS
} from "../types";
import {UserInterface, UserListInterface} from "../../models/User";

interface UserStateInterface {
    status: boolean,
    data: UserInterface | UserListInterface[],
    message: string,
    errorCode: number,
}

const initialState: UserStateInterface = {
    "status": null, "errorCode": null, "message": '', "data": []

};

export function userReducer(state = initialState, action) {
    switch (action.type) {
        case  ADD_USER_SUCCESS:
            return {
                ...initialState,
                "status": action.data.data.status,
                "errorCode": action.data.data.errorCode,
                "message": action.data.data.message,
                "data": action.data.data.data,
            };
        case  UPDATE_STATUS_USER_SUCCESS:
            return {
                ...initialState,
                "status": action.data.status,
                "errorCode": action.data.errorCode,
                "message": action.data.message,
            };
        case  GET_USERS_SUCCESS:
            return {
                ...initialState,
                "status": action.data.data.status,
                "errorCode": action.data.data.errorCode,
                "message": action.data.data.message,
                "data": action.data.data,
            };
        case  GET_USERS_FAILURE:
        case  UPDATE_STATUS_USER_FAILURE:
        case  ADD_USER_FAILURE:
            return {
                ...initialState,
                "status": action.error.status,
                "errorCode": action.error.errorCode,
                "message": action.error.message,
            };
        default:
            return state
    }
}