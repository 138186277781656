export const ENDPOINTS = {
    LOGIN: "auth/login",
    LOGOUT: "auth/logout",
    SEND_EMAIL_RESET_PASSWORD: "auth/reset",
    RESET_PASSWORD: "auth/resetPassword",
    INFORMATIONS_PROFILE: "auth/profile",
    ADD_USER: "user",
    SET_USER: "searchUser/",
    EDIT_USER: "user/",
    GET_USER: "user/",
    GET_PATIENT_OR_PARTNER: "searchUser/",
    SAVE_APPOINTMENT: "appointment/",
    GET_APPOINTMENT: "appointment/",
    ADMIN_GET_USERS: "users/?",
    ADMIN_DELETE_USERS: "updateStatus",
    UPDATE_APPOINTMENTS: "appointments/updateStatus",
    DELETE_APPOINTMENTS: "appointments",
    GET_APPOINTMENTS: "appointments/?",
    GET_MEDICAL_CARDS: "doctor/medicalCards/?",
    ADD_MEDICALCARD: "doctor/medicalCard",
    EDIT_MEDICALCARD: "doctor/medicalCard/",
    GET_PRODUCTS: "doctor/products",
    SAVE_PRESCRIPTION: "doctor/prescription/",
    GET_PRESCRIPTIONS: "doctor/prescription?",
    GET_PRESCRIPTIONS_PATIENT: "patient/prescription?",
    SAVE_FOOD_DIARY: "doctor/foodDiary/",
    UPDATE_FOOD_DIARY: "doctor/foodDiaries/updateStatus",
    UPDATE_FOOD_DIARY_PERIODS: "doctor/foodDiaryPeriods/updateStatus",
    GET_FOOD_DIARIES: "foodDiaries/?",
    SAVE_FOOD_DIARY_PERIOD: "doctor/foodDiaryPeriods/",
    GET_FOOD_DIARY_PERIOD: "foodDiaryPeriods/?",
    GET_FOOD_DIARY_PDF: "generate-pdf/?",
    UPDATE_PROFILE: "auth/update-profile/",
    CHANGE_PASSWORD: "auth/change-password/",
};