import {styled} from '@mui/material/styles';
import {Box, Container, Grid, Typography} from '@mui/material';
import React from "react";
import {DESCRIPTION_APP} from '../utils/helpers';
import myDoctiPlus from '../assets/images/mydoctiplus.png';
// ----------------------------------------------------------------------
const GridWrapper = styled(Grid)(({theme}) => `background: #A5CD48;`);
const GridLeft = styled(Grid)();
const MainContent = styled(Box)(() => `
    height: 100%;
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`);
const TypographySecondary = styled(Typography)(({theme}) => `
      align-items: center;
      justify-content: center;
      display: flex;
`);
const TopSection = styled(Box)(({theme}) => `
        display: flex;
        height: 222px;
        align-items: center;
        justify-content: center;
        border-bottom: ${theme.sidebar.dividerBg} solid 1px;
`);
export default function Accueil({component, ...props}) {
    return (<>
        <MainContent>
            <Grid
                container
                sx={{height: '100%'}}
                alignItems="stretch"
                spacing={0}>
                <GridLeft
                    xs={12}
                    md={6}
                    alignItems="center"
                    display="flex"
                    justifyContent="center"
                    item>
                    <Container maxWidth="sm">
                        <Box textAlign="center">
                            <TopSection>
                                <img src={myDoctiPlus} alt='Logo'/>
                            </TopSection>
                            <TypographySecondary
                                variant="h1"
                                fontWeight="normal">
                                {DESCRIPTION_APP}
                            </TypographySecondary>
                        </Box>
                    </Container>
                </GridLeft>
                <GridWrapper
                    xs={12}
                    md={6}
                    alignItems="center"
                    display="flex"
                    justifyContent="center"
                    item>
                    {component}
                </GridWrapper>
            </Grid>
        </MainContent>
    </>);
}