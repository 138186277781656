import axios from "axios";
import { ENDPOINTS, UNAUTHORISED_STATUS} from "../../utils/helpers";
import {getHeaders} from "../../utils/helpers/tools";

export function callLogin(email: string, password: string): Promise<any> {
     return axios.post(process.env.REACT_APP_API_BASE_URL + ENDPOINTS.LOGIN, {
        email, password
    })
        .then((response) => {
             return Promise.resolve(response)
        })
        .catch((error) => {
             return Promise.reject(error)
        });
}

export function callLogout(): Promise<any> {
    return axios.post(process.env.REACT_APP_API_BASE_URL + ENDPOINTS.LOGOUT, getHeaders())
        .then((response) => {
            return Promise.resolve(response)
        })
        .catch((error:any) => {
            if (
                error.response &&  error.response.status &&  Number(UNAUTHORISED_STATUS) === Number(error.response.status)
            ) {
                localStorage.clear();
                window.location.reload();
                return Promise.reject("common.unauthorized");
            } else {
                window.location.reload();
                return Promise.reject(error.response);
            }
        });
}

export function getProfile(): Promise<any> {
    return axios.get(process.env.REACT_APP_API_BASE_URL + ENDPOINTS.INFORMATIONS_PROFILE, getHeaders())
        .then((response) => {
            return Promise.resolve(response)
        })
        .catch((error) => {
            return Promise.reject(error)
        });
}

export function callSendEmailResetPassword(email: string): Promise<any> {
    return axios.post(process.env.REACT_APP_API_BASE_URL + ENDPOINTS.SEND_EMAIL_RESET_PASSWORD, {email})
        .then((response) => {
            return Promise.resolve(response)
        })
        .catch((error) => {
            return Promise.reject(error)
        });

}

export function callResetPassword(email: string, password: string, password_confirmation: string,token: string): Promise<any> {
    return axios.post(process.env.REACT_APP_API_BASE_URL + ENDPOINTS.RESET_PASSWORD, {
        email, password, password_confirmation,token
    }, getHeaders())
        .then((response) => {
            return Promise.resolve(response)
        })
        .catch((error) => {
            return Promise.reject(error)
        });
}