import {Box} from '@mui/material';
import {Link} from 'react-router-dom';
import {styled} from '@mui/material/styles';
import React from 'react';
import myDoctiPlus from '../../assets/images/mydoctiplus.png';
import {PATHS} from "../../APIs/paths";
import "../../assets/sass/app.scss"
const LogoWrapper = styled(Link)(
    ({theme}) => `
        color: ${theme.palette.text.primary};
        padding: ${theme.spacing(0, 1, 0, 0)};
        display: flex;
        text-decoration: none;
        font-weight: ${theme.typography.fontWeightBold};
`
);
const LogoText = styled(Box)(
    ({theme}) => `
        font-size: ${theme.typography.pxToRem(15)};
        font-weight: ${theme.typography.fontWeightBold};
`
);

function Logo() {
    return (
        <LogoWrapper to={PATHS.HOME}>
            <LogoText   ><img className="logo" src={myDoctiPlus} alt='Logo'/></LogoText>
        </LogoWrapper>
    );
}

export default Logo;