import {combineReducers} from 'redux';
import {authReducer as authState} from './authReducer';
import {resetPasswordReducer as resetPasswordState} from './resetPasswordReducer';
import {userReducer as userState} from './userReducer';
import {appointmentReducer as appointmentState} from './appointmentReducer';
import {medicalCardReducer as medicalCardState} from './medicalCardReducer';
import {prescriptionReducer as prescriptionState,} from './prescriptionReducer';
import {foodDiaryReducer as foodDiaryState,} from './foodDiaryReducer';
import {foodDiaryPeriodsReducer as foodDiaryPeriodsState,} from './foodDiaryPeriodsReducer';
import {profileReducer as profileState} from './profileReducer';

const reducer =
    combineReducers({
        profileState,
        foodDiaryPeriodsState,
        foodDiaryState,
        prescriptionState,
        authState,
        resetPasswordState,
        userState,
        appointmentState,
        medicalCardState
    });

export default reducer;