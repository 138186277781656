import {
    ADD_FOOD_DIARY_FAILURE,
    ADD_FOOD_DIARY_SUCCESS, DOWNLOAD_PDF_FOOD_DIARY_FAILURE, DOWNLOAD_PDF_FOOD_DIARY_SUCCESS,
    GET_FOOD_DIARIES_FAILURE,
    GET_FOOD_DIARIES_SUCCESS,
    UPDATE_STATUS_FOOD_DIARIES_FAILURE,
    UPDATE_STATUS_FOOD_DIARIES_SUCCESS,
    UPDATE_STATUS_USER_FAILURE,
    UPDATE_STATUS_USER_SUCCESS
} from "../types";
import {FoodDiaryInterface, FoodDiaryListInterface} from "../../models/FoodDiary";

interface FoodDiaryStateInterface {
    status: boolean,
    data: FoodDiaryInterface | FoodDiaryListInterface[],
    message: string,
    errorCode: number,
}

const initialState: FoodDiaryStateInterface = {
    "status": null, "errorCode": null, "message": '', "data": []

};
export function foodDiaryReducer(state = initialState, action) {

     switch (action.type) {
        case  ADD_FOOD_DIARY_SUCCESS:
        case  GET_FOOD_DIARIES_SUCCESS:
             return {
                "status": action.data.data.status,
                "errorCode": action.data.data.errorCode,
                "message": action.data.data.message,
                "data": action.data.data.data,
            };
         case  UPDATE_STATUS_FOOD_DIARIES_SUCCESS:
             return {
                 ...initialState,
                 "status": action.data.status,
                 "errorCode": action.data.errorCode,
                 "message": action.data.message,
             };
        case  ADD_FOOD_DIARY_FAILURE:
         case  UPDATE_STATUS_FOOD_DIARIES_FAILURE:
        case  GET_FOOD_DIARIES_FAILURE:
             return {
                ...initialState,
                "status": action.error.status,
                "errorCode": action.error.errorCode,
                "message": action.error.message,
            };
        default:
            return state
    }
}