export const PATHS = {
    HOME: "",
    HOME_PAGE: "home",
    LOGIN: "/login",
    LOGOUT: "/logout",
    MESSENGER: "messenger",
    DASHBOARD: "dashboards",
    STATUS: "status",
    ANY: "*",
    STATUS_404: "404",
    STATUS_500: "500",
    STATUS_401: "401",
    STATUS_MAINTENANCE: "maintenance",
    STATUS_COMING_SOON: "coming-soon",
    DASHBOARD_MESSENGER: "/dashboards/messenger",
    USERS: "users",
    MANAGEMENT: "management",
    MANAGEMENT_USERS: "/management/users",
    DASHBOARD_HOME: "/dashboards/home",
    APPOINTMENTS: "appointments",
    MEDICAL_CARD: "medical-cards",
    PRESCRIPTIONS: "prescriptions",
    FOOD_DIARIES: "food-diaries",
    FOOD_DIARY_PERIODS: "/food-diary-periods/:foodDiaryId",
    MANAGEMENT_APPOINTMENTS: "/management/appointments",
    MANAGEMENT_MEDICAL_CARD: "/management/medical-cards",
    MANAGEMENT_PRESCRIPTIONS: "/management/prescriptions",
    MANAGEMENT_PRESCRIPTION: "/management/prescriptions",
    MANAGEMENT_FOOD_DIARIES: "/management/food-diaries",
    MANAGEMENT_ARTICLES: "/management/articles",
    MANAGEMENT_QCM: "/management/qcms",
    MANAGEMENT_CATEGORIES: "/management/categories",
    MANAGEMENT_TEMPLATES: "/management/templates",
    MANAGEMENT_PROFILES: "/management/profile/Settings",
    MANAGEMENT_ORDERS: "/management/profile/orders",
    MANAGEMENT_PRODUCTS: "/management/profile/produits",
    SETTINGS: "settings",
    PROFILE: "profile",
    RESET_PASSWORD: "/reset-password",
    FORM_RESET_PASSWORD: "reset/:token",
}