export const LOGIN_SUCCESS = 'USERS_LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'USERS_LOGIN_FAILURE';
export const LOGOUT_SUCCESS = 'USERS_LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'USERS_LOGOUT_FAILURE';
export const GET_DATA_PROFIL_SUCCESS = 'USERS_GET_DATA_PROFIL_SUCCESS';
export const GET_DATA_PROFIL_FAILURE = 'USERS_GET_DATA_PROFIL_FAILURE';

 interface ActionLoginSuccess {
    type: string,
    data: any,
}
 interface ActionLoginFailure {
    type: string,
    error: any,
}

export  type ActionLogin=ActionLoginSuccess |ActionLoginFailure